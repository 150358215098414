var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "description-list-alignment" } },
    [
      _vm._m(0),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { md: "8" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    [
                      _c("b-card-title", [
                        _vm._v(" Description lists "),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("Horizontal")
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("b-card-body", [
                    _c("dl", { staticClass: "row" }, [
                      _c("dt", { staticClass: "col-sm-3" }, [
                        _vm._v(" Description lists ")
                      ]),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _vm._v(
                          " A description list is perfect for defining terms. "
                        )
                      ])
                    ]),
                    _c("dl", { staticClass: "row" }, [
                      _c("dt", { staticClass: "col-sm-3" }, [
                        _vm._v(" Euismod ")
                      ]),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _vm._v(
                          " Vestibulum id ligula porta felis euismod semper eget lacinia odio sem nec elit. "
                        )
                      ])
                    ]),
                    _c("dl", { staticClass: "row" }, [
                      _c("dt"),
                      _c("dd", { staticClass: "col-sm-9 ml-auto" }, [
                        _vm._v(
                          " Donec id elit non mi porta gravida at eget metus. "
                        )
                      ])
                    ]),
                    _c("dl", { staticClass: "row" }, [
                      _c("dt", { staticClass: "col-sm-3" }, [
                        _vm._v(" Malesuada porta ")
                      ]),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _vm._v(
                          " Etiam porta sem malesuada magna mollis euismod. "
                        )
                      ])
                    ]),
                    _c("dl", { staticClass: "row" }, [
                      _c("dt", { staticClass: "col-sm-3 text-truncate" }, [
                        _vm._v(" Truncated term is truncated ")
                      ]),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _vm._v(
                          " Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. "
                        )
                      ])
                    ]),
                    _c("dl", { staticClass: "row" }, [
                      _c("dt", { staticClass: "col-sm-3" }, [
                        _vm._v(" Nesting ")
                      ]),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _c("dl", { staticClass: "row" }, [
                          _c("dt", { staticClass: "col-sm-4" }, [
                            _vm._v(" Nested definition list ")
                          ]),
                          _c("dd", { staticClass: "col-sm-8" }, [
                            _vm._v(
                              " Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc. "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-card",
                [
                  _c("b-card-title", [
                    _vm._v("Description lists "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Vertical")
                    ])
                  ]),
                  _c("dl", [
                    _c("dt", [_vm._v("Description lists")]),
                    _c("dd", [
                      _vm._v(
                        "A description list is perfect for defining terms."
                      )
                    ]),
                    _c("dt", [_vm._v("Euismod")]),
                    _c("dd", [
                      _vm._v(
                        "Vestibulum id ligula porta felis euismod semper eget lacinia odio."
                      )
                    ]),
                    _c("dd", [
                      _vm._v(
                        "Donec id elit non mi porta gravida at eget metus."
                      )
                    ]),
                    _c("dt", [_vm._v("Malesuada porta")]),
                    _c("dd", [
                      _vm._v("Etiam porta sem malesuada magna mollis euismod.")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("b-card-title", [
                    _vm._v("Description lists "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Horizontal")
                    ])
                  ]),
                  _c("b-card-text", [
                    _vm._v(" Description lists with right aligned text in "),
                    _c("code", [_vm._v("<dt>")]),
                    _vm._v(" tag using "),
                    _c("code", [_vm._v(".text-right")])
                  ]),
                  _c("dl", { staticClass: "row" }, [
                    _c("dt", { staticClass: "col-sm-3 text-right" }, [
                      _vm._v(" Description lists ")
                    ]),
                    _c("dd", { staticClass: "col-sm-9" }, [
                      _vm._v(
                        " A description list is perfect for defining terms. "
                      )
                    ])
                  ]),
                  _c("dl", { staticClass: "row" }, [
                    _c("dt", { staticClass: "col-sm-3 text-right" }, [
                      _vm._v(" Euismod ")
                    ]),
                    _c("dd", { staticClass: "col-sm-9" }, [
                      _vm._v(
                        " Vestibulum id ligula porta felis euismod semper eget lacinia odio sem nec elit. "
                      )
                    ])
                  ]),
                  _c("dl", { staticClass: "row" }, [
                    _c("dt"),
                    _c("dd", { staticClass: "col-sm-9 ml-auto" }, [
                      _vm._v(
                        " Donec id elit non mi porta gravida at eget metus. "
                      )
                    ])
                  ]),
                  _c("dl", { staticClass: "row" }, [
                    _c("dt", { staticClass: "col-sm-3 text-right" }, [
                      _vm._v(" Malesuada porta ")
                    ]),
                    _c("dd", { staticClass: "col-sm-9" }, [
                      _vm._v(
                        " Etiam porta sem malesuada magna mollis euismod. "
                      )
                    ])
                  ]),
                  _c("dl", { staticClass: "row" }, [
                    _c(
                      "dt",
                      { staticClass: "col-sm-3 text-right text-truncate" },
                      [_vm._v(" Truncated term is truncated ")]
                    ),
                    _c("dd", { staticClass: "col-sm-9" }, [
                      _vm._v(
                        " Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. "
                      )
                    ])
                  ]),
                  _c("dl", { staticClass: "row" }, [
                    _c("dt", { staticClass: "col-sm-3 text-right" }, [
                      _vm._v(" Nesting ")
                    ]),
                    _c("dd", { staticClass: "col-sm-9" }, [
                      _c("dl", { staticClass: "row" }, [
                        _c("dt", { staticClass: "col-sm-4" }, [
                          _vm._v(" Nested definition list ")
                        ]),
                        _c("dd", { staticClass: "col-sm-8" }, [
                          _vm._v(
                            " Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc. "
                          )
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "group-area mt-1" }, [
      _c("h4", [_vm._v("Description list alignment")]),
      _c("p", [
        _vm._v(
          " Align terms and descriptions horizontally by using our grid system’s predefined classes (or semantic mixins). For longer terms, you can optionally add a "
        ),
        _c("code", { staticClass: "highlighter-rouge" }, [
          _vm._v(".text-truncate")
        ]),
        _vm._v(" class to truncate the text with an ellipsis. ")
      ]),
      _c("hr")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }