var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "lists" } },
    [
      _vm._m(0),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-card",
                { attrs: { title: "Lists Unstyled" } },
                [
                  _c("b-card-text", [
                    _vm._v(" Use class "),
                    _c("code", [_vm._v(".list-unstyled")]),
                    _vm._v(" for Lists Unstyled. It remove the default "),
                    _c("code", { staticClass: "highlighter-rouge" }, [
                      _vm._v("list-style")
                    ]),
                    _vm._v(
                      " and left margin on list items (immediate children only). "
                    ),
                    _c("strong", [
                      _vm._v(
                        "This only applies to immediate children list items"
                      )
                    ]),
                    _vm._v(
                      ", meaning you will need to add the class for any nested lists as well. "
                    )
                  ]),
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _c("li", [_vm._v("Lorem ipsum dolor sit amet")]),
                    _c("li", [_vm._v("Consectetur adipiscing elit")]),
                    _c("li", [_vm._v("Integer molestie lorem at massa")]),
                    _c("li", [_vm._v("Facilisis in pretium nisl aliquet")]),
                    _c("li", [
                      _vm._v(" Nulla volutpat aliquam velit "),
                      _c("ul", [
                        _c("li", [_vm._v("Phasellus iaculis neque")]),
                        _c("li", [_vm._v("Purus sodales ultricies")]),
                        _c("li", [_vm._v("Vestibulum laoreet porttitor sem")]),
                        _c("li", [_vm._v("Ac tristique libero volutpat at")])
                      ])
                    ]),
                    _c("li", [_vm._v("Faucibus porta lacus fringilla vel")]),
                    _c("li", [_vm._v("Aenean sit amet erat nunc")]),
                    _c("li", [_vm._v("Eget porttitor lorem")])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-card",
                [
                  _c("b-card-title", [
                    _vm._v(" Lists Unordered "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Default")
                    ])
                  ]),
                  _c("b-card-text", [
                    _vm._v(
                      " List of items in which the order does not explicitly matter. Use "
                    ),
                    _c("code", [_vm._v(".list-style-circle")]),
                    _vm._v(" or "),
                    _c("code", [_vm._v(".list-style-square")]),
                    _vm._v(
                      " class in unordered list to add circle or square bullet points. "
                    )
                  ]),
                  _c("ul", [
                    _c("li", [_vm._v("Lorem ipsum dolor sit amet")]),
                    _c("li", [_vm._v("Consectetur adipiscing elit")]),
                    _c("li", [_vm._v("Integer molestie lorem at massa")]),
                    _c("li", [_vm._v("Facilisis in pretium nisl aliquet")]),
                    _c("li", [
                      _vm._v(" Nulla volutpat aliquam velit "),
                      _c("ul", [
                        _c("li", [_vm._v("Phasellus iaculis neque")]),
                        _c("li", [_vm._v("Purus sodales ultricies")]),
                        _c("li", [_vm._v("Vestibulum laoreet porttitor sem")]),
                        _c("li", [_vm._v("Ac tristique libero volutpat at")])
                      ])
                    ]),
                    _c("li", [_vm._v("Faucibus porta lacus fringilla vel")]),
                    _c("li", [_vm._v("Aenean sit amet erat nunc")]),
                    _c("li", [_vm._v("Eget porttitor lorem")])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-card",
                [
                  _c("b-card-title", [
                    _vm._v("Lists Ordered "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Default")
                    ])
                  ]),
                  _c("b-card-text", [
                    _vm._v(
                      " List of items in which the order does explicitly matter. Use "
                    ),
                    _c("code", [_vm._v('<ol type="1|a|A|i|I">')]),
                    _vm._v(
                      ", The type attribute specifies the kind of marker to use in the list. "
                    )
                  ]),
                  _c("ol", [
                    _c("li", [_vm._v("Lorem ipsum dolor sit amet")]),
                    _c("li", [_vm._v("Consectetur adipiscing elit")]),
                    _c("li", [_vm._v("Integer molestie lorem at massa")]),
                    _c("li", [_vm._v("Facilisis in pretium nisl aliquet")]),
                    _c("li", [
                      _vm._v(" Nulla volutpat aliquam velit "),
                      _c("ol", [
                        _c("li", [_vm._v("Phasellus iaculis neque")]),
                        _c("li", [_vm._v("Purus sodales ultricies")]),
                        _c("li", [_vm._v("Vestibulum laoreet porttitor sem")]),
                        _c("li", [_vm._v("Ac tristique libero volutpat at")])
                      ])
                    ]),
                    _c("li", [_vm._v("Faucibus porta lacus fringilla vel")]),
                    _c("li", [_vm._v("Aenean sit amet erat nunc")]),
                    _c("li", [_vm._v("Eget porttitor lorem")])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { title: "Lists icons" } },
                [
                  _c("b-card-text", [
                    _vm._v(" list of terms with icons, use "),
                    _c("code", [_vm._v(".list-style-icons")]),
                    _vm._v(
                      " class. You can use any icon from Vuexy Admin icon types. "
                    )
                  ]),
                  _c("ul", { staticClass: "list-style-icons" }, [
                    _c(
                      "li",
                      [
                        _c("feather-icon", {
                          attrs: { icon: "ArrowRightIcon" }
                        }),
                        _vm._v("Facilisis in pretium nisl aliquet")
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("feather-icon", {
                          attrs: { icon: "ArrowRightIcon" }
                        }),
                        _vm._v("Nulla volutpat aliquam velit "),
                        _c("ul", { staticClass: "list-style-icons" }, [
                          _c(
                            "li",
                            [
                              _c("feather-icon", {
                                attrs: { icon: "ChevronRightIcon" }
                              }),
                              _vm._v("Phasellus iaculis neque")
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c("feather-icon", {
                                attrs: { icon: "ChevronRightIcon" }
                              }),
                              _vm._v("Ac tristique libero volutpat at")
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("feather-icon", {
                          attrs: { icon: "ArrowRightIcon" }
                        }),
                        _vm._v("Faucibus porta lacus fringilla vel")
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("feather-icon", {
                          attrs: { icon: "ArrowRightIcon" }
                        }),
                        _vm._v("Aenean sit amet erat nunc")
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-card",
                [
                  _c("b-card-title", [
                    _vm._v("Inline Lists "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Default")
                    ])
                  ]),
                  _c("b-card-text", [
                    _vm._v(" Remove a list’s bullets and apply some light "),
                    _c("code", [_vm._v("margin")]),
                    _vm._v(" with a combination of two classes, "),
                    _c("code", [_vm._v(".list-inline")]),
                    _vm._v(" and "),
                    _c("code", [_vm._v(".list-inline-item")]),
                    _vm._v(". ")
                  ]),
                  _c("b-card-text", [
                    _vm._v(
                      " Use inline numbers, alphabet, icons etc... for ordered Inline List. "
                    )
                  ]),
                  _c("ul", { staticClass: "list-inline" }, [
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v(" Chocolate ")
                    ]),
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v(" Cake ")
                    ]),
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v(" Ice-Cream ")
                    ])
                  ]),
                  _c("ul", { staticClass: "list-inline" }, [
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v(" 1. Chocolate ")
                    ]),
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v(" 2. Cake ")
                    ]),
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v(" 3. Ice-Cream ")
                    ])
                  ]),
                  _c("ul", { staticClass: "list-inline" }, [
                    _c(
                      "li",
                      { staticClass: "list-inline-item" },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "DollarSignIcon" }
                        }),
                        _c("span", [_vm._v("250")])
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "list-inline-item" },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "DollarSignIcon" }
                        }),
                        _c("span", [_vm._v("110")])
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "list-inline-item" },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "DollarSignIcon" }
                        }),
                        _c("span", [_vm._v("890")])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "group-area mt-1" }, [
      _c("h4", [_vm._v("Lists")]),
      _c("p", [
        _vm._v(" All lists - "),
        _c("code", [_vm._v("<ul>")]),
        _vm._v(", "),
        _c("code", [_vm._v("<ol>")]),
        _vm._v(", and "),
        _c("code", [_vm._v("<dl>")]),
        _vm._v(" - have their margin-top removed and a "),
        _c("code", [_vm._v("margin-bottom: 1rem")]),
        _vm._v(". Nested lists have no "),
        _c("code", [_vm._v("margin-bottom")]),
        _vm._v(". ")
      ]),
      _c("hr")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }